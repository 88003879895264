<template>
  <div>
    <b-modal id="modal-update-adjustment-reason" cancel-variant="outline-secondary" :ok-title="$t('Update')"
      :cancel-title="$t('Close')" ok-variant="success" centered modal-class="modal-success"
      :title="$t('Update adjustment reason')" no-enforce-focus @ok="confirmUpdate" @hidden="adjustmentReason ==null">
      <!-- form -->
      <validation-observer ref="updateAdjustmentReasonModal">
        <b-form method="POST" @submit.prevent="validationForm">
          <b-form-group label-for="name">
            <label class="mb-1">{{ $t("Name") }}</label>
            <validation-provider #default="{ errors }" name="name" rules="required">
              <b-form-input id="name" v-model="adjustmentReason.name"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Field: is_bonus  -->
          <b-form-group label-for="bonus">
            <label class="mb-1">{{ $t("Is Bonus") }}</label>
            <validation-provider #default="{ errors }" name="bonus" rules="required">
              <b-form-radio-group id="bonus" name="bonus" v-model="adjustmentReason.isBonus"
                :options="options"></b-form-radio-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import {
  BLink,
  BRow,
  BCol,
  BTabs,
  BTab,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormRadio,
  BButton,
  BInputGroup,
  BFormRadioGroup,
  BInputGroupPrepend,
  BModal,
  VBModal,
  BInputGroupAppend,
  BFormTextarea,
  BBadge,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import router from "@/router";
import baseUrl from "@/libs/base-url";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BLink,
    BRow,
    BCol,
    BTabs,
    BTab,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BButton,
    BInputGroup,
    BFormRadioGroup,
    BInputGroupPrepend,
    BModal,
    BFormTextarea,
    BBadge,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    quillEditor,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    adjustmentReasonDetail: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      adjustmentReason: {
        name: "",
        isBonus: ''
      },
    };
  },
  watch: {
    adjustmentReasonDetail: {
      handler(newVal) {
        if (newVal) {
          this.adjustmentReason = {
            id: newVal.id,
            name: newVal.name,
            isBonus: newVal.is_bonus + '',
          };
        }
      },
      deep: true,
    },
  },
  methods: {
    validationForm() {
      this.$refs.updateAdjustmentReasonModal.validate().then((success) => {
        if (success) {
          this.updateAdjustmentReason(this.adjustmentReason);
        }
      });
    },
    async updateAdjustmentReason(adjustmentReasonData) {
      await this.$store
        .dispatch("reason/updateAdjustmentReason", adjustmentReasonData)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.code == "00") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || "success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              this.$emit("refetch-data");
              this.$nextTick(() => {
                setTimeout(() => {
                  this.$bvModal.hide("modal-update-adjustment-reason");
                  this.resetModal();
                }, "200");
              });
              this.$emit("refetch-data");
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          }
        });
    },
    resetModal() {
      this.adjustmentReason = {
        name: "",
        isBonus: ""
      };
    },
    confirmUpdate(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.validationForm();
    },
  },
  setup() {
    const options = [
      { value: '1', text: 'Có' },
      { value: '2', text: 'Không' }
    ]
    return {
      options
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>